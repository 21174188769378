import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntelligenceFields } from 'modules/intelligent-layer/hooks';
import { BasicFieldsForm } from 'modules/intelligent-layer/forms/IntelligenceFieldsForm';
import { useTranslation } from 'utils/translation';
import { useModal } from '../../../../components/v2/Modal/useModal';
import { useModalContent } from '../../../../components/v2/Modal/useModalContent';
import Spacer from '../../../../components/v2/Spacer/Spacer';
import { mapValuesToPayload } from '../../../../modules/intelligent-layer/utils';
import { useCreateNewDevice } from '../hooks';
import Typography from 'components/v2/Typography/Typography';
import Section from 'components/v2/Section/Section';
import Loader from 'components/v2/Loader';
import Button, { ButtonGroup } from 'components/v2/Button/Button';
import Stack from '@mui/material/Stack/Stack';
import { applyFilters } from '../../../../core';
import Filters from '../../../../core/filters';
import { useQuery } from '@tanstack/react-query';
import { refetchActiveQueries } from '../../../../utils/QueryClientProvider';
import { useDataContext } from '../../../../components/v2/Data/DataContext';

const defaultRequiredFields = ['serial_number', 'device_type'];

const SuccessNotice = ({
  onCreateNext,
  onClose,
}: {
  onClose(): void;
  onCreateNext(): void;
}) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Stack direction="column" alignItems="center">
        <Typography variant="h2">{t('Device has been created')}</Typography>
        <Spacer size={4} />
        <ButtonGroup>
          <Button onClick={onCreateNext}>{t('Create next device')}</Button>
          <Button onClick={onClose} variant="text">
            {t('Close')}
          </Button>
        </ButtonGroup>
      </Stack>
    </Section>
  );
};

type Props = {
  onClose(): void;
  onSuccess?(): void;
  defaultType?: { label: string; value: number } | null;
};

const NewDeviceForm = ({ onClose, onSuccess, defaultType = null }: Props) => {
  const { t } = useTranslation();
  const mutation = useCreateNewDevice();
  const [selectedType, setSelectedType] = useState<any | null>(defaultType);
  const modalContent = useModalContent();

  useEffect(() => {
    if (mutation.isError) {
      modalContent.scrollToTop();
    }
  }, [mutation.isError]);

  const formKey = selectedType?.value || 'unknown';

  const { data = [], isLoading } = useIntelligenceFields([
    'unique-object',
    selectedType?.label?.toLowerCase() || '',
  ]);

  const defaultValuesQuery = useQuery({
    queryFn: () =>
      applyFilters(
        Filters.device.newDeviceDefaultValues,
        {
          device_type: selectedType,
        },
        {
          deviceType: selectedType,
        },
      ),
    queryKey: ['new_device_form_default_values', formKey],
    refetchInterval: 0,
    gcTime: Infinity,
  });

  const fields = useMemo(() => {
    return data.map((item) => {
      if (defaultRequiredFields.includes(item.name)) {
        item.settings.required = true;
      }

      return item;
    });
  }, [data]);

  const handleChange = useCallback((values: any) => {
    if (values.hasOwnProperty('device_type')) {
      setSelectedType(values['device_type']);
    }
  }, []);

  const onSubmit = async (values: any) => {
    const payload = mapValuesToPayload(values);

    try {
      await mutation.mutateAsync(payload);
      onSuccess?.();
      void refetchActiveQueries();
    } catch (error: any) {
      return Promise.reject({
        response: error?.response,
      });
    }
  };

  return (
    <>
      <Section>
        <Typography variant="h1">{t('Create new device')}</Typography>
      </Section>
      {isLoading || defaultValuesQuery.isLoading ? (
        <Section>
          <Loader />
        </Section>
      ) : mutation.isSuccess ? (
        <SuccessNotice onCreateNext={mutation.reset} onClose={onClose} />
      ) : (
        <BasicFieldsForm
          key={formKey}
          defaultValues={defaultValuesQuery.data}
          onChange={handleChange}
          fields={fields}
          persister={onSubmit}
        />
      )}
    </>
  );
};

export const useCreateNewDeviceForm = () => {
  const modal = useModal();

  return (
    props: Omit<React.ComponentProps<typeof NewDeviceForm>, 'onClose'> = {},
  ) => {
    modal.open(({ close }) => <NewDeviceForm {...props} onClose={close} />, {
      disableBackdropClose: true,
    });
  };
};

export default NewDeviceForm;
